import layout from '@/views/front/layout'
const frontRouter = {
    path: "/",
    component: layout,
    children: [{
        path: "/home",
        component: () =>
            import("../../views/front/home/home.vue"),
        meta: { title: "", keepAlive: false },
    }, {
        path: "/technology",
        component: () =>
            import("../../views/front/technology/technology.vue"),
        meta: { title: "音频定位技术", keepAlive: false },
    }, {
        path: "/products",
        component: () =>
            import("../../views/front/products/products.vue"),
        meta: { title: "产品服务", keepAlive: false },
    },  {
        path: "/products/info",
        component: () =>
            import("../../views/front/products/productsInfo.vue"),
        meta: { title: "产品介绍", keepAlive: false },
    }, {
        path: "/news",
        component: () =>
            import("../../views/front/news/news.vue"),
        meta: { title: "行业资讯", keepAlive: false },
    }, {
        path: "/news/info",
        component: () =>
            import("../../views/front/news/newsInfo.vue"),
        meta: { title: "行业资讯", keepAlive: false },
    }, {
        path: "/order",
        component: () =>
            import("../../views/front/order/order.vue"),
        meta: { title: "订购", keepAlive: false },
    },  {
        path: "/order/search",
        component: () =>
            import("../../views/front/order/orderSearch.vue"),
        meta: { title: "订单查询", keepAlive: false },
    },  {
        path: "/order/success",
        component: () =>
            import("../../views/front/order/orderSuccess.vue"),
        meta: { title: "订购成功", keepAlive: false },
    },  {
        path: "/about",
        component: () =>
            import("../../views/front/about/about.vue"),
        meta: { title: "产品服务", keepAlive: false },
    }
    ],
};
export default frontRouter;
