import Vue from 'vue'
//样式
import 'normalize.css/normalize.css' // a modern alternative to CSS resets
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import '@/styles/index.scss' // global css
//JS
import './plugins/axios'
import App from './App.vue'
import router from './router'
ElementUI.Dialog.props.lockScroll.default = false;
Vue.use(ElementUI);

import animated from 'animate.css'
Vue.use(animated);

import rules from "@/utils/rules.js";
Vue.prototype.$rules = rules;

import AMap from "vue-amap"; //引入高德地图
// 高德地图
Vue.use(AMap);
AMap.initAMapApiLoader({
  key: "ff5fc22da04b9f6cccd6784d347dd7d7",
  plugin: [
    "AMap.Scale",
    "AMap.ToolBar"
  ],
  v: "1.4.11",
});

// 全局校验
// import rules from "@/utils/rules.js";
// Vue.prototype.$rules = rules;

// 全局过滤器

// 页面加载前
router.beforeEach((to, from, next) => {
  // 页面加title
  if (to.meta.title) {
    document.title = to.meta.title + "- 德清知路导航有限公司官网";
  } else {
    document.title = " 德清知路导航有限公司官网";
  }
  next();
});
router.afterEach((to,from,next) => {
  window.scrollTo(0,0);
});
Vue.config.productionTip = false;
new Vue({
  router,
  render: h => h(App)
}).$mount('#app');


