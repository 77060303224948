/*
 ******消息框******
 */
import { MessageBox, Message } from "element-ui";

export function BoxAlert(msg) {
    MessageBox.alert(msg, "提示");
}

export function BoxConfirm(msg, next) {
    MessageBox.confirm(msg, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
    }).then(() => {
        next();
    }).catch((e) => {});
}

export function BoxError(msg) {
    MessageBox.alert(msg, "错误");
}

export function MsgSuccess(msg) {
    Message.success({
        message: msg ? msg : "操作成功",
        duration: 2000,
    });
}

export function MsgError(msg) {
    Message.error({
        message: msg ? msg : "操作失败",
        duration: 2000,
    });
}