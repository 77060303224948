<template>
  <div class="app-content">
    <webTop />
    <section class="app-main">
      <keep-alive v-if="this.$route.meta.keepAlive">
        <router-view :key="key" />
      </keep-alive>
      <router-view :key="key" v-if="!this.$route.meta.keepAlive" />
    </section>
    <webFooter />
  </div>
</template>

<script>
import webFooter from "./footer.vue";
import webTop from "./header.vue";
export default {
  name: "layout",
  components: {
    webTop,
    webFooter,
  },
  computed: {
    key() {
      return this.$route.path;
    },
  },
};
</script>

<style lang="scss" scoped>
.app-content{
  width: 100%;
  min-width: 1200px;
}
.app-main{
  // padding-top:90px;
}
</style>
