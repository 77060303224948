<template>
  <div :class="{ 'header-content': true, 'header-bg': isScroll }">
    <div class="header-info">
      <a class="header-logo" href=""></a>
      <el-menu mode="horizontal" :router="true" ref="memm">
        <template v-for="menu in menuList">
          <el-menu-item v-if="menu.isLeaf" :key="menu.id" :index="menu.path" @mouseleave.native="mouseleave(menu.id, $event)" @mouseover.native="mouseover(menu.id, $event)">
            <el-link :href="menu.path" :underline="false">{{ menu.name }}</el-link>
            <div class="point" v-if="$route.path == menu.path || hoverIndex == menu.id || ($route.path == '/news/info' && menu.path == '/news')" />
          </el-menu-item>
          <el-submenu v-if="!menu.isLeaf" :show-timeout="50" :key="menu.id" index="/" popper-class="submenu-popper">
            <template slot="title"
              >{{ menu.name }}
              <div class="point" v-if="$route.path == '/order' || $route.path == '/order/search'"
            /></template>
            <el-menu-item v-for="subMenu in menu.menuList" :index="subMenu.path" :key="subMenu.id">
              <div class="submenu-item">
                <el-link :href="subMenu.path" :underline="false">{{ subMenu.name }}</el-link>
              </div></el-menu-item
            >
          </el-submenu>
        </template>
      </el-menu>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      menuList: [
        { id: "1", name: "首页", path: "/home", isLeaf: true },
        { id: "2", name: "技术", path: "/technology", isLeaf: true },
        { id: "3", name: "产品", path: "/products", isLeaf: true },
        { id: "4", name: "资讯", path: "/news", isLeaf: true },
        {
          id: "5",
          name: "订购",
          path: "/order",
          isLeaf: false,
          menuList: [
            { id: "51", name: "在线订购", path: "/order", isLeaf: true },
            { id: "52", name: "订购查询", path: "/order/search", isLeaf: true },
          ],
        },
        { id: "6", name: "联系", path: "/about", isLeaf: true },
      ],
      hoverIndex: -1,
      isScroll: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    // this.$refs["memm"].open("12");
  },
  methods: {
    mouseover(index, e) {
      this.hoverIndex = index;
    },
    mouseleave(index, e) {
      this.hoverIndex = -1;
    },
    handleScroll() {
      let scrollTop = document.documentElement.scrollTop;
      if (scrollTop >= 90) {
        this.isScroll = true;
      } else {
        this.isScroll = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.header-bg {
  background-color: rgba($color: #021229, $alpha: 0.75);
}
.header-content {
  width: 100%;
  position: fixed;
  z-index: 900;
  // background-image: url(/images/header_bg.png);
  // background-color: rgba($color: #021229, $alpha: 0.1);
  height: 80px;
  .header-info {
    width: 1200px;
    margin: 0 auto;
    height: 100%;
    padding: 5px 0px 0px 20px;
  }
  @media screen and (max-width: 1200px) {
    position: relative;
    background-color: transparent;
    background-image: url(/images/header_bg.jpg);
    .header-info {
      width: 100%;
      padding: 0px;
    }
  }
  .header-logo {
    float: left;
    width: 120px;
    height: 100%;
    background: url("/images/logo.png") center no-repeat;
    background-size: 100%;
  }
  .point {
    width: 8px;
    height: 8px;
    background-color: #00ffc0;
    margin: -14px auto;
    border-radius: 4px;
  }
  /deep/ .el-menu {
    float: right;
    background-color: transparent;
  }

  /deep/ .el-menu-item {
    height: 70px;
    margin-left: 35px;
    vertical-align: middle;
    border-bottom: 0px solid #00ffc0;
    a {
      color: #ebecee;
      // font-weight: bolder;
      font-size: 16px;
      letter-spacing: 2px;
      line-height: 70px;
    }
    &:hover {
      color: #fff;
      background-color: transparent;
    }
  }

  /deep/.el-submenu {
    height: 80px;
    line-height: 80px;
    margin-left: 35px;
    .el-submenu__title {
      height: 70px;
      line-height: 70px;
      color: #ebecee;
      // font-weight: bolder;
      font-size: 16px;
      letter-spacing: 2px;
      border-bottom: 0px solid #00ffc0;
      &:hover {
        color: #fff;
        background-color: transparent;
      }
    }
  }

  /deep/ .is-active {
    color: #ebecee;
    // font-weight: bolder;
    font-size: 16px;
    border-bottom: 0px solid #00ffc0;
    background-color: transparent;
    .el-submenu__title {
      color: #fff;
      border-bottom: 0px solid #00ffc0;
    }
  }

  /deep/ .el-menu.el-menu--horizontal {
    border-bottom: solid 0px #e6e6e6;
  }
  /deep/ .el-menu--horizontal > .el-menu-item:not(.is-disabled):focus,
  .el-menu--horizontal > .el-menu-item:not(.is-disabled):hover,
  .el-menu--horizontal > .el-submenu .el-submenu__title:hover {
    background-color: transparent;
    color: #fff;
  }

  /deep/ .el-submenu__icon-arrow {
    display: none;
  }
}
</style>
