import Vue from "vue";
import VueRouter from "vue-router";
import frontRouter from "./modules/front";

Vue.use(VueRouter);

const routes = [{
    // 登录
    path: "/",
    redirect: "/home",
},
{
    // 404
    path: "*",
    component: () =>
        import("../views/errors/404.vue"),
    meta: { title: "404", keepAlive: false },
},
    frontRouter,
];

const router = new VueRouter({
    mode: 'history',  //去掉url中的#
    routes,
});

export default router;